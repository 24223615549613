/* eslint-disable no-shadow */
import { UsStateType, UsStateValue } from 'lib/common/states';

export enum BillableType {
  ach = 'BankAccount',
  credit_card = 'CreditCard',
  pay_pal = '',
}

export type CardBrand = 'discover' | 'master' | 'visa';

export type CardBrandsOrderKey = 'discover' | 'mastercard' | 'visa';

export enum LocationPaymentMethodProvider {
  PS = 'PS',
  paysafe = 'paysafe',
  paysafe_sightline = 'paysafe_sightline',
  worldpay_apple_pay = 'worldpay_apple_pay',
}

export enum PaymentMethodKind {
  ach = 'ach',
  credit = 'credit',
  debit = 'debit',
  pay_pal = 'pay_pal',
}

export enum ProviderType {
  ach = 'ach',
  credit_card = 'credit_card',
  pay_pal = 'pay_pal',
}

export interface ServiceFeeType {
  amount: number;
  fixed: number;
  threshold: number;
  percentage: number;
}

export interface LocationPaymentMethodsType {
  add_enabled: boolean;
  brand: CardBrand;
  kind: PaymentMethodKind;
  logo_visible: boolean;
  metadata: {
    display_logo: string;
    service_fee: ServiceFeeType[];
  };
  pay_enabled: boolean;
  provider: LocationPaymentMethodProvider | null;
}

export type BusinessHours = {
  open: {
    hour: number;
    minute: number;
  };
  close: {
    hour: number;
    minute: number;
  };
};

interface LocationSettings {
  card_brands_order?: { [key in CardBrandsOrderKey]: number };
  provider_types_order: { [key in ProviderType]: number };
  scratchers_schedule?: BusinessHours;
  service_fee_configs?: Omit<ServiceFeeType, 'amount'>[];
}

export interface LocationSmartTokenResponse {
  accessToken: string;
  accessTokenExpiresAt: string;
  client: {
    id: string;
  };
}

export interface LocationType {
  code: string;
  location_payment_methods: LocationPaymentMethodsType[];
  max_credit_amount: number;
  name: string;
  settings: LocationSettings;
  time_zone: string;
}
export interface LocationResponse {
  location: LocationType;
}

export type GeoByIpResponse = {
  isVpn: boolean;
  lat: number;
  lng: number;
  locationCode: UsStateValue;
};

export type GeoByIp = GeoByIpResponse & {
  displayText: UsStateType;
};

export interface GeoSessionId {
  dProfileSessionId: string;
  html5SessionId: string;
}
