/* eslint-disable no-shadow */
import { LotteryType, PlayOptionType } from 'lib/types/lottery';

export enum PickType {
  PickYourOwn,
  QuickPick,
  SuperPick,
  BulkOrder,
}

export interface Play {
  amount_due: number;
  complimentary: boolean;
  created_at_timestamp: number;
  credits: number;
  discount: number;
  drawing_entries: DrawingEntry[];
  drawing_at_timestamp: number;
  games: Game[];
  has_results: boolean;
  id: number;
  is_big_winner: boolean;
  is_processed: boolean;
  is_winner: boolean;
  location_code: string;
  lottery_id: number;
  options:
    | {
        [key in PlayOptionType]?: ['0'] | ['1'];
      }
    | null;
  order_token: string;
  pool_drawing_id?: string;
  pool_id?: string;
  pool_membership_id: null;
  prize: null;
  promo_code_applied: boolean;
  service_fee: number;
  service_percentage: number;
  subtotal: number;
  ticket_slips: TicketSlip[];
  total: number;
  total_drawing_entries: number;
  total_quickpicks_won: number;
  total_tickets: number;
  total_winnings: number;
  updated_at_timestamp: number;
  user_subscription_id: null;
  void: boolean;
}

/** Types for Order Details (Purchased Plays) start */
/**
 * PlayDetail extends Play with lottery data to enable displaying additional information on the Order Details screen
 */
export interface PlayDetail extends Play {
  lottery: LotteryType;
}

export interface DrawingEntry {
  created_at_timestamp: number;
  display_id: string;
  drawing_at_timestamp: number;
  game_id: number;
  id: number;
  is_winner: number;
  lottery_result_numbers: LotteryResultNumbers | null;
  prize: null | string;
  prize_amount: number;
  prize_claimed: boolean;
  prize_quick_pick: boolean;
  quickpick: boolean;
  serial_number: string;
  ticket_image: string;
  ticket_image_back: string;
  ticket_slip_id: number;
  updated_at_timestamp: number;
}

export interface LotteryResultNumbers {
  [key: string]: string[];
}

export interface Ticket {
  prizeModifier: number | null;
  refunded: boolean;
  rows: TicketRow[];
  ticketImageBack: string | null;
  ticketImageFront: string | null;
  voidDescription: string | null;
  voidReason: string | null;
  wagerOptionsText: string | null;
}

export interface TicketRow {
  displayNumbers: TicketResultNumbers[];
  order: number;
  prizeLabel: string;
  ticketId: number;
}

interface TicketResultNumbers {
  color: string;
  isMatched?: boolean;
  rule: string;
  value: string;
}

export interface DrawingResults {
  displayNumbers: TicketResultNumbers[];
  hasDrawn: boolean;
  playOptionName: string;
  playOptionValue: string | null;
}

export interface Game {
  id: number;
  numbers: Numbers;
  ticket_slip_id: number;
  play_id: number;
  options: {
    entries_count: string[];
    wager_amount: string[];
    wager_type: string[];
  } | null;
  lottery_ticket_receipt_url: string;
  created_at_timestamp: number;
  updated_at_timestamp: number;
  lottery_id: number;
  matches: Numbers;
}

interface TicketSlip {
  id: number;
  prize_modifier: number | null;
  quickpick_play_id: number | null;
  refunded: boolean;
  total_quickpicks_won: number;
  void_reason_description: string | null;
  void_reason_slug: string | null;
}

export interface Numbers {
  [key: string]: string[];
}
/** Types for Order Details (Purchased Plays) end */

/** Types for Checkout Start */
export interface PlayRequest {
  client_location_code?: string;
  checkout_token?: number;
  games_attributes: GamesAttribute[];
  lat?: number;
  lng?: number;
  lottery_id: number;
  options: {
    [k: string]: string[];
  };
  promo_code?: string;
  purchasing?: boolean;
}

export interface GamesAttribute {
  numbers: {
    [k: string]: string[];
  };
  options?: {
    entries_count: string[];
    wager_amount: string[];
    wager_type: string[];
  };
  randomly_generated: boolean;
}
/** Types for Checkout End */
